import { BaseRecord, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { Authenticated, LayoutWrapper } from "@pankod/refine-core";

import {
    Typography,
    Button,
    Space,
    PageHeader,
    Icons,
    Spin,
    Card,
    Table,
    Drawer,
    Form,
    Input,
    Modal,
    InputNumber,
    message,
    FormInstance,
} from "@pankod/refine-antd";
import { useEffect, useRef, useState } from "react";
import { Popconfirm } from "@pankod/refine-antd";

const { Title,Text } = Typography;

const { EditOutlined, RedoOutlined, SaveOutlined, DeleteOutlined } = Icons;

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, visible }: { form: FormInstance; visible: any }) => {
    const prevVisibleRef = useRef<boolean>();
    useEffect(() => {
        prevVisibleRef.current = visible;
    }, [visible]);
    const prevVisible = prevVisibleRef.current;

    useEffect(() => {
        if (!visible && prevVisible) {
            form.resetFields();
        }
    }, [visible]);
};

const rowKey = "level"
const conf_key = "level"
export const Level: React.FC = () => {

    const translate = useTranslate();
    // const { useParams } = useRouterContext();
    // const {
    //     resource: routeResourceName,
    //     action: routeFromAction,
    //     id: idFromRoute,
    // } = useParams<ResourceRouterParams>();

    const [editTable] = Form.useForm();
    const [editingKey, setEditingKey] = useState<number>();
    const editableAction = (_: any, row: any, index: any) => {
        return index === editingKey ? (<Space>
            <Typography.Link onClick={() => {editTable.submit()}} style={{ marginRight: 8 }}>Save</Typography.Link>
            <Button type="text" onClick={() => { setEditingKey(undefined);}}>Cancel</Button>
        </Space>) : (
        <Space>
            <Button icon={<EditOutlined />} onClick={() => {
                setEditingKey(index);
                editTable.setFieldsValue({ ...row });
            }}></Button>
            <Popconfirm
                key="delete" okType="danger"
                okText={translate("buttons.delete", "Delete")}
                cancelText={translate("buttons.cancel", "Cancel")}
                title={translate("buttons.confirm", "Are you sure?")}
                okButtonProps={{ disabled: isLoading }}
                onConfirm={(): void => onEditFormDelete(index)}
            >
                <Button danger loading={isLoading} icon={<DeleteOutlined />}></Button>
            </Popconfirm>
        </Space>)
    }
    const columns = [
        { title: '等级', dataIndex: 'level' },
        { title: '经验值', dataIndex: 'exp', editable:true },
        { title: '体力值', dataIndex: 'hp', editable:true },
        { title: '随机材料', dataIndex: 'mat', editable:true },
        { width: '25%', title: translate("table.actions"), key: 'action', render: editableAction}
    ];
    const editableColumns = columns.map(col => {
        if (!col.editable) return col;
        else return {
          ...col,
          render: (text: any, row: any, index: any) => {
            if (index === editingKey) {
                return (
                    <Form.Item name={col.dataIndex} style={{ margin: 0 }} >
                        <Input type="number"/>
                    </Form.Item>
                );
            }
            return <Text>{text}</Text>; 
        }}
    });
    const onEditFormDelete = (index: any) => {
        if(index!=undefined){
            record.splice(index, 1);
            mutate({ resource: "setting", id: conf_key, values: JSON.stringify(record) });
        }
    }
    const onEditFormFinish = (values: any) => {
        if(editingKey!=undefined){
            record[editingKey] = {...record[editingKey],...values};
            setRecord([...record]);
            mutate({ resource: "setting", id: conf_key, values: JSON.stringify(record) }, {
                onSuccess: () => {
                    setEditingKey(undefined);
                }
            });
        }
    };

    const { data: resp, isLoading, remove, refetch, isFetching } = useOne({
        id: conf_key, resource: "setting",
    });
    // const record = resp?.data?.data;
    const [record,setRecord] = useState(resp?.data?.data);
    useEffect(() => {
        if (resp?.data?.data && !isFetching) {
            setRecord([...resp.data.data]);
            console.log(`[${conf_key}]`,resp.data,{ isFetching });
        }
    }, [resp, isFetching]);
    

    const buttonDisabled = isLoading || isFetching;
    const handleRefresh = () => {
        remove();
        refetch();
    };


    // Drawer
    const [drawerValue, setDrawerValue] = useState("");
    const [Drawervisible, setDrawerVisible] = useState(false);
    const showDrawer = () => {
        setDrawerValue(JSON.stringify(record));
        setDrawerVisible(true);
    };
    const mutationResult = useUpdate<BaseRecord>();
    const { mutate, isLoading: mutateIsLoading } = mutationResult;
    const handleSaveJson = () => {
        // valid
        try {
            JSON.parse(drawerValue);
        } catch (error) {
            return message.error('不是有效的json');
        }

        // submit
        mutate({ resource: "setting", id: conf_key, values: drawerValue }, {
            onSuccess: (_) => {
                setDrawerVisible(false);
            },
        });
    };

    // modal
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    useResetFormOnCloseModal({ form, visible: modalVisible });
    const handleOk = (_: any) => {
        form.submit();
    };
    const onModalFormFinish = (values: any) => {
        let moadlData: any[] = record ?? [];
        moadlData.push(values);
        // moadlData.sort(function(fst, sec) {
        //     if (fst[rowKey] < sec[rowKey]) return -1;
        //     if (fst[rowKey] > sec[rowKey]) return 1;
        //     return 0;
        // });

        // submit
        mutate({ resource: "setting", id: conf_key, values: JSON.stringify(moadlData) }, {
            onSuccess: (data) => {
                handleRefresh();
                setModalLoading(false);
                setModalVisible(false);
            },
            onError: () => {
                handleRefresh();
                setModalLoading(false);
            }
        });
    };
    const handleCancel = () => {
        setModalVisible(false);
    };

    return (
        <Authenticated>
            <LayoutWrapper>
                <PageHeader title="相关配置 > 等级/经验/体力/材料" ghost={false} extra={
                    <Space key="extra-buttons" wrap>
                        <Button icon={<RedoOutlined spin={isFetching} />}
                            onClick={() => handleRefresh()}
                        > {translate("buttons.refresh", "Refresh")} </Button>
                        <Button disabled={buttonDisabled} icon={<EditOutlined />}
                            onClick={() => showDrawer()}
                        > JSON </Button>
                    </Space>
                }>
                    <Spin spinning={buttonDisabled}>
                        <Card bordered={false} >
                            <Title level={5}>用户每一级等级需要消耗的经验值和对于的体力值，升级可获得随机材料。</Title>
                            <Form form={editTable} onFinish={onEditFormFinish}>
                                <Table rowKey={rowKey} dataSource={record} columns={editableColumns} style={{}} />
                            </Form>
                            <div style={{ textAlign: "center", marginTop: 24 }} >
                                <Button type="primary" disabled={buttonDisabled} onClick={() => { setModalVisible(true) }}>
                                    添加配置
                                </Button>
                            </div>
                        </Card>
                    </Spin>
                </PageHeader>

                <Modal
                    title="添加配置" visible={modalVisible}
                    onOk={handleOk} onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>取消</Button>,
                        <Button key="submit" type="primary" loading={modalLoading} onClick={handleOk}>保存</Button>
                    ]}
                >
                    <Form form={form} labelCol={{ span: 4 }} onFinish={onModalFormFinish}>
                        <Form.Item rules={[{ required: true }]} label="等级" name="level"><Input /></Form.Item>
                        <Form.Item rules={[{ required: true }]} label="经验值" name="exp"><InputNumber style={{ width: '100%' }} /></Form.Item>
                        <Form.Item rules={[{ required: true }]} label="体力值" name="hp"><InputNumber style={{ width: '100%' }} /></Form.Item>
                        <Form.Item rules={[{ required: true }]} label="随机材料" name="mat"><InputNumber style={{ width: '100%' }} /></Form.Item>
                    </Form>
                </Modal>

                <Drawer title="编辑JSON" placement="right" onClose={() => { setDrawerVisible(false) }} visible={Drawervisible}>
                    <Input.TextArea rows={10} value={drawerValue} onChange={e => setDrawerValue(e.target.value)} />
                    <Space
                        key="action-buttons"
                        style={{ float: "right", marginTop: 24, marginRight: 24 }}
                    >
                        <Button type="primary" icon={<SaveOutlined spin={mutateIsLoading} />} htmlType="submit" onClick={handleSaveJson}>
                            {translate("buttons.save", "Save")}
                        </Button>
                    </Space>
                </Drawer>

            </LayoutWrapper>
        </Authenticated>
    );
};

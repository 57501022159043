import { Refine } from "@pankod/refine-core";
import { notificationProvider, ErrorComponent, ConfigProvider } from "@pankod/refine-antd";
import routerProvider from "@pankod/refine-react-router";
import "styles/antd.less";
import zh_CN from 'antd/lib/locale/zh_CN';
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import { useEffect } from "react";

import "@pankod/refine-antd/dist/styles.min.css";
import { authProvider, axiosInstance, API_URL } from "./authProvider";
import { PostList, PostCreate, PostEdit, PostShow } from "pages/posts";
import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";
import { useTranslation } from "react-i18next";
import { Login } from "pages/login";

import { DashboardPage } from "./pages/dashboard";
import { PostReview } from "pages/post-review";
import { 
  Checkin_loop, Health_points, Level, LP_mining, MKV_hold, 
  NFT_level_cost, NFT_level_power, Random_material, Daily_lottery, Week_shop 
} from "pages/config";
import { User_list } from "pages/user/list";
import { White_list } from "pages/user/white_list";
import JsonServer from "components/simple-rest";

function App() {
  const { t, i18n } = useTranslation();
  const dataProvider = JsonServer(API_URL,axiosInstance);
  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  const locale = i18nProvider.getLocale();
  useEffect(() => {
      if (locale === "sg") dayjs.locale("zh-cn");
      else dayjs.locale("en");
  }, [locale]);

  return (
    <ConfigProvider locale={locale === "sg" ? zh_CN : undefined}>
      <Refine
        dataProvider={dataProvider}
        authProvider={authProvider}
        LoginPage={Login}
        resources={[
          {
            name: "posts",
            create: PostCreate,
            list: PostList,
            edit: PostEdit,
            show: PostShow,
          },
        ]}
        routerProvider={{
            ...routerProvider,
            routes: [
                {exact: true, component: PostReview,path: "/post-review"},
                {exact: true, component: User_list,path: "/user/list"},
                {exact: true, component: White_list,path: "/user/white_list"},
                {exact: true, component: Level,path: "/config/level"},
                {exact: true, component: Health_points,path: "/config/health_points"},
                {exact: true, component: Checkin_loop,path: "/config/checkin_loop"},
                {exact: true, component: MKV_hold,path: "/config/mkv_hold"},
                {exact: true, component: LP_mining,path: "/config/lp_mining"},
                {exact: true, component: NFT_level_cost,path: "/config/nft_level_cost"},
                {exact: true, component: NFT_level_power,path: "/config/nft_level_power"},
                {exact: true, component: Week_shop,path: "/config/week_shop"},
                {exact: true, component: Random_material,path: "/config/random_material"},
                {exact: true, component: Daily_lottery,path: "/config/daily_lottery"},
            ] as typeof routerProvider.routes,
        }}
        Title={Title}
        Header={Header}
        Sider={Sider}
        Footer={Footer}
        Layout={Layout}
        OffLayoutArea={OffLayoutArea}
        DashboardPage={DashboardPage}
        i18nProvider={i18nProvider}
        notificationProvider={notificationProvider}
        catchAll={<ErrorComponent />}>
      </Refine>
    </ConfigProvider>
  );
}
    
export default App;

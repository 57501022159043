import { BaseRecord, useOne, useTranslate, useUpdate } from "@pankod/refine-core";
import { Authenticated, LayoutWrapper } from "@pankod/refine-core";

import {
    Typography,
    Button,
    Space,
    PageHeader,
    Icons,
    Spin,
    Card,
    Table,
    Drawer,
    Form,
    Input,
    Modal,
    InputNumber,
    message,
    FormInstance,
} from "@pankod/refine-antd";
import { useEffect, useRef, useState } from "react";

const { Title,Text } = Typography;

const { EditOutlined, RedoOutlined, SaveOutlined } = Icons;

// reset form fields when modal is form, closed
const useResetFormOnCloseModal = ({ form, visible }: { form: FormInstance; visible: any }) => {
    const prevVisibleRef = useRef<boolean>();
    useEffect(() => {
        prevVisibleRef.current = visible;
    }, [visible]);
    const prevVisible = prevVisibleRef.current;

    useEffect(() => {
        if (!visible && prevVisible) {
            form.resetFields();
        }
    }, [visible]);
};

export const Checkin_loop: React.FC = () => {

    const translate = useTranslate();
    const { data, isLoading, remove, refetch, isFetching } = useOne({
        resource: "setting", id: "checkin_loop",
    });
    const record = data?.data;
    const buttonDisabled = isLoading || isFetching;

    const handleRefresh = () => {
        remove();
        refetch();
    };

    // Drawer
    const [drawerValue, setDrawerValue] = useState("");
    const [Drawervisible, setDrawerVisible] = useState(false);
    const showDrawer = () => {
        setDrawerValue(JSON.stringify(record?.data));
        setDrawerVisible(true);
    };
    const mutationResult = useUpdate<BaseRecord>();
    const { mutate, isLoading: mutateIsLoading } = mutationResult;
    const handleSaveJson = () => {
        // valid
        try {
            JSON.parse(drawerValue);
        } catch (error) {
            return message.error('不是有效的json');
        }

        // submit
        mutate({ resource: "setting", id: "checkin_loop", values: drawerValue }, {
            onSuccess: (data) => {
                setDrawerVisible(false);
            },
        });
    };

    // modal
    const [form] = Form.useForm();
    const [modalVisible, setModalVisible] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    useResetFormOnCloseModal({ form, visible: modalVisible });
    const handleOk = (value: any) => {
        form.submit();
    };
    const onModalFormFinish = (values: any) => {
        let moadlData: any[] = record?.data ?? [];
        moadlData.push(values);

        // submit
        mutate({ resource: "setting", id: "checkin_loop", values: JSON.stringify(moadlData) }, {
            onSuccess: (data) => {
                handleRefresh();
                setModalLoading(false);
                setModalVisible(false);
            },
            onError: () => {
                handleRefresh();
                setModalLoading(false);
            }
        });
    };
    const handleCancel = () => {
        setModalVisible(false);
    };

    return (
        <Authenticated>
            <LayoutWrapper>
                <PageHeader title="相关配置 > 7天循环签到" ghost={false} extra={
                    <Space key="extra-buttons" wrap>
                        <Button icon={<RedoOutlined spin={isFetching} />}
                            onClick={() => handleRefresh()}
                        > {translate("buttons.refresh", "Refresh")} </Button>
                        <Button disabled={buttonDisabled} icon={<EditOutlined />}
                            onClick={() => showDrawer()}
                        > JSON </Button>
                    </Space>
                }>
                    <Spin spinning={buttonDisabled}>
                        <Card bordered={false} >
                            <Title level={5}>7天进行循环签到，如果用户断签，那就从第一天开始。签到按照如下固定参数设置，经验奖励和随机材料。</Title>
                            <Table rowKey="day" dataSource={record?.data} >
                                <Table.Column
                                    title="天数" dataIndex="day"
                                    render={day => (<Text>第{day}天</Text>)}
                                />
                                <Table.Column title="奖励经验" dataIndex="exp" />
                                <Table.Column title="随机材料" dataIndex="mat" />
                            </Table>
                            <div style={{ textAlign: "center", marginTop: 24 }} >
                                <Button type="primary" disabled={buttonDisabled} onClick={() => { setModalVisible(true) }}>
                                    添加配置
                                </Button>
                            </div>
                        </Card>
                    </Spin>
                </PageHeader>

                <Modal
                    title="添加配置" visible={modalVisible}
                    onOk={handleOk} onCancel={handleCancel}
                    footer={[
                        <Button key="back" onClick={handleCancel}>取消</Button>,
                        <Button key="submit" type="primary" loading={modalLoading} onClick={handleOk}>保存</Button>
                    ]}
                >
                    <Form form={form} labelCol={{ span: 6 }} onFinish={onModalFormFinish}>
                        <Form.Item rules={[{ required: true }]} label="天数" name="day">
                            <InputNumber style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item rules={[{ required: true }]} label="奖励经验" name="exp"><InputNumber style={{ width: '100%' }} /></Form.Item>
                        <Form.Item rules={[{ required: true }]} label="随机材料" name="mat"><InputNumber style={{ width: '100%' }} /></Form.Item>
                    </Form>
                </Modal>

                <Drawer title="编辑JSON" placement="right" onClose={() => { setDrawerVisible(false) }} visible={Drawervisible}>
                    <Input.TextArea rows={10} value={drawerValue} onChange={e => setDrawerValue(e.target.value)} />
                    <Space
                        key="action-buttons"
                        style={{ float: "right", marginTop: 24, marginRight: 24 }}
                    >
                        <Button type="primary" icon={<SaveOutlined spin={mutateIsLoading} />} htmlType="submit" onClick={handleSaveJson}>
                            {translate("buttons.save", "Save")}
                        </Button>
                    </Space>
                </Drawer>

            </LayoutWrapper>
        </Authenticated>
    );
};


import axios from 'axios';
import { AuthProvider } from "@pankod/refine-core";

export const TOKEN_KEY = "maker-auth";
export const API_URL = "https://admin.st-lein.workers.dev";
// export const API_URL = "http://127.0.0.1:8787";
export const axiosInstance = axios.create();

export const authProvider: AuthProvider = {
  login: async ({ username, password, code }) => {
    let url = API_URL + (code ? "/login_github": "/login");
    const { data, status } = await axiosInstance.post(url, { username, password, code });
    if (status === 200) {
      if (data.code === 0) {
        console.log(data);
        localStorage.setItem(TOKEN_KEY, data.jwt);
        localStorage.setItem("user",JSON.stringify(data.user) );
        localStorage.setItem("expiresAt", data.expires_at.toString());
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${data.jwt}`;
        return Promise.resolve();
      } else {
        if (data.message) return Promise.reject(new Error(data.message));
      }
    }
    return Promise.reject();
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem("user");
    localStorage.removeItem("expiresAt");
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      const expiresAt = localStorage.getItem("expiresAt");
      if (expiresAt) {
          return new Date().getTime() / 1000 < +expiresAt
              ? Promise.resolve()
              : Promise.reject();
      }
    }
    
    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const user = localStorage.getItem("user");
    if (user) {
        return Promise.resolve(JSON.parse(user));
    }
  },
};
